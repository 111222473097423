import { Form, Input } from "react-daisyui";
import { convertToUnderScoreVariable } from "../../../utils/ConvertToUnderScoreVariable";

const PropertyInput = ({
  name,
  type = "text",
  className = "",
  onChange,
  formLabel = true,
  value,
  required = true,
  register = null,
  error = {},
  regex,
}) => {
  const elementName = convertToUnderScoreVariable(name);

  const applyFullWidth = new Set(["text", "number", "password"]);
  if (applyFullWidth.has(type)) className += "w-full";

  const formRegistration =
    register &&
    register(elementName, {
      required: required,
      pattern: regex,
    });

  return (
    <div className="my-1">
      {formLabel && <Form.Label title={name} />}
      <Input
        name={elementName}
        type={type}
        placeholder={name.toLowerCase()}
        className={"input input-bordered " + className}
        onChange={onChange}
        value={value}
        {...formRegistration}
      />
      {error && error.type === "required" && (
        <p className="text-xs text-error">{name} is required</p>
      )}
      {error && error.message && (
        <p className="text-xs text-error">
          {name} {error.message}
        </p>
      )}
    </div>
  );
};

export default PropertyInput;
