const CenterFrame = ({ children, ...props }) => {
  return (
    <div
      className="flex flex-col items-center justify-center p-8 mt-12"
      {...props}
    >
      {children}
    </div>
  );
};

export default CenterFrame;
