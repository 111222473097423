import { useState } from "react";
import { Button, Navbar } from "react-daisyui";
import { Link } from "react-router-dom";
import ZescribeLogo from "../../img/ZescribeLogo.png";

const jwtCookie = localStorage.getItem("jwt");
const hasJwtCookie = jwtCookie ? true : false;
const decodedJwt = JSON.parse(localStorage.getItem("decodedJwt"));
const isPremium = decodedJwt?.user?.membership === "premium" || false;

const logoutButton = () => {
  return (
    <Button className="btn btn-outline m-2 align-center" onClick={logoutUser}>
      logout
    </Button>
  );
};
const LogoutDropdown = () => {
  return (
    <Dropdown
      label={"Profile"}
      options={["account", "logout"]}
      customButtons={{ logout: logoutButton }}
    />
  );
};

const LoginAndRegisterDropdown = () => {
  return <Dropdown label={"Profile"} options={["login", "register"]} />;
};

// TODO: export Dropdown to its own component
const Dropdown = ({ label, options = {}, customButtons }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const close = () => setIsOpen(false);

  let DropdownButton = ({ option }) => {
    return (
      <Link to={option} onClick={close} className="btn btn-outline m-2">
        {option}
      </Link>
    );
  };

  return (
    <div className="dropdown dropdown-end">
      <label onClick={toggle} tabIndex="0" className="btn btn-ghost m-1">
        {label}
      </label>
      {isOpen && (
        <ul
          tabIndex="0"
          className="dropdown-content menu p-2 shadow bg-base-200 rounded-box w-52"
        >
          {options.map((option, index) => {
            if (customButtons) {
              console.log(customButtons);
              DropdownButton = customButtons[option] ?? DropdownButton;
            }
            return (
              <li key={`dropdown-${option}-${index}`}>
                <DropdownButton option={option} />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const logoutUser = () => {
  localStorage.clear("jwt");
  window.location.reload();
};

const NavBarLink = ({ children, to, color = "ghost" }) => {
  return (
    <Link to={to} className={`btn btn-${color} m-2`}>
      {children}
    </Link>
  );
};

const UpgradeLink = () => {
  return (
    <NavBarLink to="/pricing" color="primary">
      Upgrade To Premium ⭐️
    </NavBarLink>
  );
};

const PricingLink = () => {
  return <NavBarLink to="/pricing">Pricing</NavBarLink>;
};

const DesktopNavBarEnd = () => {
  return (
    <Navbar.End className="hidden md:flex">
      {!isPremium && <UpgradeLink />}
      {!isPremium && <PricingLink />}
      {hasJwtCookie ? <LogoutDropdown /> : <LoginAndRegisterDropdown />}
    </Navbar.End>
  );
};

const MobileNavBarEnd = () => {
  const loggedInOptions = ["account", "logout"];
  const loggedOutOptions = ["login", "register"];

  let options = hasJwtCookie ? loggedInOptions : loggedOutOptions;
  options = [!isPremium && "pricing", ...options];

  const customButtons = { logout: logoutButton };

  return (
    <Navbar.End className="md:hidden">
      <Dropdown
        label={"Menu"}
        options={options}
        customButtons={customButtons}
      />
    </Navbar.End>
  );
};

const CustomNavbar = () => {
  return (
    <div className="flex w-full component-preview p-4 items-center justify-center">
      <Navbar className="rounded-lg backdrop-filter backdrop-blur-lg bg-opacity-30 sticky fixed top-0 z-10 bg-white m-5 w-11/12 border-solid border-2 border-primary shadow-lg">
        <Navbar.Start>
          <Link to="/" className="text-xl font-bold normal-case" color="ghost">
            <img src={ZescribeLogo} className="w-40" alt="Zescribe Logo" />
          </Link>
        </Navbar.Start>
        <DesktopNavBarEnd />
        <MobileNavBarEnd />
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
