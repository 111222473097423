const HeaderText = ({ title, subtitle, color, textAlign = "center" }) => {
  return (
    <div className={`my-12 mx-22 text-${textAlign} ${color}`}>
      <h1 className="text-5xl font-bold">{title}</h1>
      <p className="font-light my-2">{subtitle}</p>
    </div>
  );
};
export default HeaderText;

export const HeaderLogo = ({ src, alt, subtitle }) => {
  return (
    <div className="my-10 text-center">
      <img src={src} className="w-30" alt={alt} />

      <p className="font-light my-2">{subtitle}</p>
    </div>
  );
};
