import { convertToUnderScoreVariable } from "../../utils/ConvertToUnderScoreVariable";

const TabGroup = ({ tabs = [], activeTab, setActiveTab, size = "md" }) => {
  return (
    <div className="tabs">
      {tabs.map((tab, index) => {
        const tabValue = convertToUnderScoreVariable(tab);
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <div
            className={`tab tab-lifted tab-${size} ${
              activeTab === tabValue && "tab-active font-bold"
            }`}
            key={`tab-${tabValue}-${index}`}
            onClick={() => setActiveTab(tabValue)}
          >
            {tab}
          </div>
        );
      })}
    </div>
  );
};

export default TabGroup;
