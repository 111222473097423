import { useState } from "react";
import { Form } from "react-daisyui";
import { convertToUnderScoreVariable } from "../../../utils/ConvertToUnderScoreVariable";

const ButtonGroup = ({ name, onChange }) => {
  const [selected, setSelected] = useState("1");
  const [custom, setCustom] = useState("");

  const RadioButton = ({ value }) => {
    return (
      <input
        type="radio"
        name={convertToUnderScoreVariable(name) + "_options"}
        value={value}
        data-title={value}
        className="btn btn-outline border-r-0 border-gray-200"
        onChange={(e) => {
          setSelected(e.target.value);
          setCustom("");
          onChange(e);
        }}
        checked={!custom && selected === value ? true : false}
      />
    );
  };

  const CustomValue = () => {
    return (
      <input
        type="number"
        placeholder="custom"
        name={convertToUnderScoreVariable(name) + "_options"}
        value={custom}
        className={`btn ${
          custom ? "btn-primary" : "btn-outline"
        } w-28 border-l-1 border-gray-200`}
        onChange={(e) => {
          setSelected(e.target.value);
          setCustom(e.target.value);
          onChange(e);
        }}
      />
    );
  };

  return (
    <div className="my-1">
      <Form.Label title={name} />
      <div className="btn-group">
        <RadioButton value={"1"} />
        <RadioButton value={"2"} />
        <RadioButton value={"3"} />
        <RadioButton value={"4"} />
        <RadioButton value={"5"} />
        {CustomValue()}
      </div>
    </div>
  );
};

export default ButtonGroup;
