import { Button } from "react-daisyui";

const SubmitButton = ({ children, className, loading }) => {
  return (
    <div className="mt-6">
      <Button type="submit" className={"w-full " + className}>
        {loading && <span class="loading loading-spinner"></span>}
        {children}
      </Button>
    </div>
  );
};

export default SubmitButton;
